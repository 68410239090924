@charset 'UTF-8';

.play {
  text-align: center;
  width: 100%;

  a {
    display: inline-block;
    margin: 0 auto;
    svg {margin: 0 auto;}
  }
}

.marvel-device .play {
  position: absolute;
  top: 58%;
  left: 0;
  width: 100%;
  z-index: 200;
  margin: 0;

  a {
    display: inline-block;
    color: $white !important;
  }
}

.icon-link {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 700;
  color: $blue-whale;
  margin: 0 ($grid-gutter-width / 2) ($grid-gutter-width / 2);

  .icon {
    margin-right: 10px;
    @media screen and (max-width: $screen-sm-max) {
      svg {
        display: block;
        width: 40px;
        height: 40px;
      }
    }
  }

  &:hover {
    color: $blue-whale;
    text-decoration: none;
  }
}

.icon-links {
  display: flex;
  flex-wrap: wrap;
}

.icon-play {
  svg {
    .triangle {
      transform-origin: 50% 50%;
      pointer-events: none;
    }

    a:hover & {
      circle {fill: darken(#d3422f, 5%);}
      .triangle {
        animation-name: bounceIn;
        animation-duration: 1s;
        animation-fill-mode: both;
      }
    }
  }
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    transform: scale(0.8, 0.8);
  }

  20% {
    transform: scale(1.1, 1.1);
  }

  40% {
    transform: scale(0.9, 0.9);
  }

  60% {
    transform: scale(1.03, 1.03);
  }

  80% {
    transform: scale(0.97, 0.97);
  }

  to {
    transform: scale(1, 1);
  }
}
