@charset "UTF-8";

@import url(https://fonts.googleapis.com/css?family=PT+Serif);

@font-face {
  font-family: 'Runda';
  src: url('../fonts/type_b.eot');
  src: url('../fonts/type_b.eot?#iefix') format('embedded-opentype'),
       url('../fonts/type_b.woff2') format('woff2'),
       url('../fonts/type_b.woff') format('woff'),
       url('../fonts/type_b.ttf') format('truetype'),
       url('../fonts/type_b.svg#runda_boldbold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Runda';
  src: url('../fonts/type.eot');
  src: url('../fonts/type.eot?#iefix') format('embedded-opentype'),
       url('../fonts/type.woff2') format('woff2'),
       url('../fonts/type.woff') format('woff'),
       url('../fonts/type.ttf') format('truetype'),
       url('../fonts/type.svg#rundanormal') format('svg');
  font-weight: normal;
  font-style: normal;
}

// init:
@import 'main-variables';
@import 'bootstrap';
@import 'typography';
@import 'helpers';

// atoms
// @import 'atoms/xxx';

// molecules
@import 'molecules/devices';
@import 'molecules/play';
@import 'molecules/player';

// organisms
@import 'organisms/header';
@import 'organisms/introduction';
@import 'organisms/steps';
@import 'organisms/faq';
@import 'organisms/footer';
