@charset 'UTF-8';

.steps {
  @media screen and (max-width: $screen-xs-max) {
    text-align: center;

    .text-right,
    .text-left {
      text-align: center !important;
    }
  }
}
