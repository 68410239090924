@charset 'UTF-8';

header {
  background: $blue-whale;
  color: $downy;
  padding: 2em 0 2em 0;
  font-size: $font-size-small;

  h1 {
    font-family: $font-family-sans-serif;
    color: $white;
    font-size: 2.2em;
    margin: 0;
    font-weight: bold;
  }

  p {
    margin: 0;
    margin-top: 1em;
    padding: 0;
  }

  a,
  a:hover {text-decoration: none;}

  @media screen and (max-width: $screen-xs-max) {
    h1,
    img {
      font-size: 1.8em;
      max-width: 100%;
    }

    //.pull-right {float: none !important;}
  }
}
