@charset 'UTF-8';

html {
  font-size: $base;
  line-height: $gr * 1em;

  @media screen and (max-width: $screen-sm-max) {
    font-size: floor(($base * 0.92));
  }

  @media screen and (max-width: $screen-xs-max) {
    font-size: floor(($base * 0.88));
  }

  @media screen and (max-width: $screen-xs) {
    font-size: floor(($base * 0.8));
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0.3em 0;
  line-height: 1.3em;
}

h1, .h1 {
  font-family: $font-family-serif;
  font-weight: normal;
  position: relative;

  &:after {
    content: '\00a0';
    position: absolute;
    left: 0;
    bottom: -0.5em;
    width: 40px;
    height: 1px;
    display: block;
    background: $blue-whale;
  }

  .text-center &:after,
  &.text-center:after {
    left: 50%;
    margin-left: -20px;
  }

  .hero &:after {background: $white;}
}

body:not(.home) .container > h1.text-center {
  position: relative;
  z-index: $zindex-navbar + 1;
  margin-top: -120px;
  margin-bottom: 2em;

  @media screen and (max-width: $screen-sm-max) {
    margin-top: -80px;
    margin-bottom: 1.8em;
  }
}

h2,
.h2,
.h1 {
  font-weight: normal;
}

small {
  color: $cadet-blue;
}

.text-light {font-weight: normal;}
.text-primary {color: $blue-whale;}

