@charset 'UTF-8';

.faq {
  position: relative;
  overflow: hidden;

  .dl-horizontal dt {
    width: 10em;
    white-space: normal;
    margin-bottom: 0.5em;
  }

  dd {margin-bottom: 1em;}

  @media screen and (max-width: $screen-xs-max) {
    dd {margin-bottom: 1.5em;}
  }
}

.faq-inner {
  width: 200%;
  border-radius: 50% 50% 0 0 ;
  overflow: hidden;
  margin-left: -50%;
  background: $blue-whale;
  color: $white;

  @media screen and (max-width: $screen-xs-max) {
    width: 400%;
    margin-left: -150%;
  }
}

.faq-content {
  position: relative;
  width: 50%;
  left: 25%;
  padding: 3em 0;

  @media screen and (max-width: $screen-xs-max) {
    width: 25%;
    left: 37.5%;
  }
}
