@charset 'UTF-8';

.marvel-device {
  transform: scale(0.8);
  margin: -15% 0;
  max-width: 100%;

  &.landscape,
  &.iphone6,
  &.iphone5s,
  &.nexus5,
  &.s5,
  &.lumia920,
  &.ipad,
  &.htc-one {
    height: auto !important;
    width: auto !important;
  }
  // @media only screen and (max-width: $screen-sm-max) {
  //   padding: 0 !important;
  //   background: none !important;
  //   box-shadow: none !important;
  //   &:before,
  //   &:after,
  //   .top-bar,
  //   .sleep,
  //   .volume,
  //   .bottom-bar {display:none;}
  // }
}

.marvel-device .screen {
  position: relative;
  z-index: 50;
}
