@charset 'UTF-8';

.video-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 200ms ease-out;
  backface-visibility: hidden;
  transform: scaleX(1);
  background: $black;
  z-index: 999999;

  &.closed {
    transform: scale3d(0,0,1);
    pointer-events: none;
    opacity: 0;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 20px;
    background: $black;
    width: 40px;
    height: 40px;
    text-align: center;

    span {
      display: block;
      font-size: 2em;
      color: $white;
      line-height: 1;
      transition: transform 200ms ease;
    }

    &:hover {
      text-decoration: none;
      span {
        transform: rotate(90deg);
      }
    }
  }
}
