@charset 'UTF-8';

.spacer {
  height: 4.0625em;

  &.spacer-xl {height: 7.5em;}
  &.spacer-lg {height: 5.625em;}
  &.spacer-md {height: 2.5em;}
  &.spacer-sm {height: 1.875em;}
  &.spacer-ssm {height: 0.9375em;}
  &.spacer-xs {height: 0.625em;}

  @media screen and (max-width: $screen-xs-max) {
    height: 1.875em;

    &.spacer-xl {height: 3.125em;}
    &.spacer-lg {height: 3.125em;}
    &.spacer-md {height: 1.55em;}
    &.spacer-sm {height: 1.25em;}
    &.spacer-xs {height: 0.625em;}
  }
}

.whity {
  background: $white;

  .hero + & {
    margin-top: -150px;
    padding-top: 150px;
  }
}

.atheny {
  background: $athens-gray;
}

.atheny-light {
  background: lighten($athens-gray, 5%);
}

.row-flex {
  display: flex;
  align-items: center;

  @media screen and (max-width: $screen-xs-max) {
    display: block;
  }
}
