@charset 'UTF-8';

footer {
  background: lighten($blue-whale, 5%);
  color: $white;
  font-size: $font-size-small;
  padding: 1em 0;

  .pull-right a {
    color: #ef2678;
    font-weight: bold;
  }

  @media screen and (max-width: $screen-xs-max) {
    text-align: center;

    .pull-right {
      float: none !important;
      display: block;
    }
  }
}
