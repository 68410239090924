// Basics ---------------------------------
$base:     18px;
$gr:       1.61803398875;
$gutter:   2em;
$grid-gutter-width: $gutter;


// Colors ---------------------------------
$fire-bush:     #df933b;
$valencia:      #d3422f;
$pesto:         #727c36;

$cadet-blue:    #acb6c2;
$water-leaf:    #9fdde8;
$downy:         #6fc3ca;
$blue-whale:    #052347;

$white:         #fff;
$athens-gray:   #e6e9ed;
$abbey:         #58595b;
$black:         #000;
