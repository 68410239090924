@charset 'UTF-8';

.introduction {
  position: relative;
  overflow: hidden;

  .container {
    position: relative;
    z-index: 10;
    padding-top: 5%;
    padding-bottom: 8%;
    background-repeat: no-repeat;
    background-image: url('/svg/intro.svg');
    background-position: right bottom;
    background-size: 100%;
    overflow: hidden;
  }

  .container-inner {
    position: relative;
    width: 150%;
    left: -25%;
  }

  .intro-content:after {
    content: '\00a0';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%;
    background: $abbey;
  }

  .marvel-device {
    width: 35% !important;
    float: left;
    margin: -12% 0;
  }

  .media-body {padding-left: $grid-gutter-width / 2;}

  .h1 {margin-top: 0;}
  p {font-size: 1.2em}
  .text-info {color: darken($water-leaf, 40%);}

  @media screen and (max-width: $screen-md-max) {
    .container {
      padding-top: 8%;
      padding-bottom: 12%;
    }

    p {font-size: 1.15em}
  }

  @media screen and (max-width: $screen-sm-max) {font-size: 1em;}

  @media screen and (max-width: $screen-xs-max) {
    .container {
      padding-top: 5%;
      padding-bottom: 80%;
      background-size: 170%;
    }

    .intro-content:after {
      height: 10%;
    }

    .marvel-device {
      position: relative;
      left: 50%;
      display: inline-block;
      width: 280px !important;
      float: none;
      transform: scale(0.5);
      margin: -25% 0;
      margin-left: -170px;

      img {max-width: 280px;}
    }

    .media-left {display: none;}

    .media-body {
      padding-left: 0;
      img {max-width: 150px;}
    }

    p {font-size: 1em}
  }

  @media screen and (max-width: 400px) {.marvel-device {margin-top: -40%;margin-bottom: -40%;}}
}

.lt-ie10 .introduction .container {
  background-image: url('/img/intro.png');
}


.intro-inner {
  width: 200%;
  border-radius: 0 0 50% 50%;
  overflow: hidden;
  margin-left: -50%;

  @media screen and (max-width: $screen-xs-max) {
    width: 400%;
    margin-left: -150%;
  }
}

.intro-content {
  background: $water-leaf no-repeat;
  position: relative;
  width: 50%;
  left: 25%;

  @media screen and (max-width: $screen-xs-max) {
    width: 25%;
    left: 37.5%;
  }
}

.icon-wrapper {
  display: inline-block;
  border-radius: 1em;
  overflow: hidden;
}
